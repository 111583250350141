@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

/* Disable User Selection */
.app {
    /* font-family: 'Montserrat', sans-serif; */

    /* Disable Selection */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Standard */

    /* Diable blue highlight when click [For Mobile Chrome] */
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    zoom: 0.85;

    @media screen and (min-width: 450px) {
        zoom: 1;
    }
}

.app:focus {
    outline: none !important;
}

.select-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #aaa;
}

/* Hide Scrollbar Class ".hide-scrollbar" */
.hide-scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.hide-scrollbar::-webkit-scrollbar-track {
    background: #fff;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
    background: #fff;
}

/* Custom Scrollbar Type 1 */
.custom-scrollbar-1::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.custom-scrollbar-1::-webkit-scrollbar-track {
    background: #bdd0fb;
}

.custom-scrollbar-1::-webkit-scrollbar-thumb {
    background: theme("colors.primary");
}

.heading {
    font-family: "Lato", "sans-serif";
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
}

.press:active {
    transition: all;
    transition-duration: 200ms;
    scale: 0.97;
}

.custom-container {
    padding-left: 32px;
    padding-right: 32px;
}

@media screen and (min-width: 768px) {
    .custom-container {
        padding-left: 56px;
        padding-right: 56px;
    }
}

@media screen and (min-width: 1280px) {
    .custom-container {
        padding-left: 112px;
        padding-right: 112px;
    }
}

.custom-shadow {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
}

/* Swiper.js */
/* Styling Hero and Blog Section */
.swiper {
    width: 100%;
}

.swiper-slide img {
    width: 100%;
    /* max-height: calc(100vh - 100px); */
    object-fit: cover;
}

.swiper-button-next {
    display: none;
}

.swiper-button-next::after {
    display: none;
}

.swiper-button-prev {
    display: none;
}

.swiper-button-prev::after {
    display: none;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    /* Set the scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Thumb color */
    border-radius: 10px;
    /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Thumb hover color */
}

/* career section  */
.transition-max-height {
    transition: max-height 0.3s ease-in-out;
    /* Adjust duration and easing as needed */
}

/* cutom buttion css */

.custom-button-left {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: transform 0.3s ease-in-out;
}

@media (min-width: 1601px) {
    .custom-button-left {
        left: 128px !important;
    }
}

@media (min-width: 1301px) and (max-width: 1600px) {
    .custom-button-left {
        left: 70px !important;
    }
}

@media (min-width: 1301px) and (max-width: 1600px) {
    .custom-button-left {
        left: 70px !important;
    }
}

@media (min-width: 1001px) and (max-width: 1300px) {
    .custom-button-left {
        left: 50px;
    }
}

@media (min-width: 801px) and (max-width: 1000px) {
    .custom-button-left {
        left: 30px;
    }
}

@media (min-width: 501px) and (max-width: 800px) {
    .custom-button-left {
        left: 10px;
        bottom: 0px !important;
    }
}

@media (min-width: 301px) and (max-width: 500px) {
    .custom-button-left {
        left: 5px;
        bottom: 0px !important;
    }
}

@media (max-width: 300px) {
    .custom-button-left {
        left: 0px;
        bottom: 0px !important;
    }
}

/* solution section */
@media (min-width: 1000px) and (max-width: 1800px) {
    .custom-gap {
        gap: 20px !important;
        /* Customize the gap between your videos */
    }
}